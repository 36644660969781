import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import asyncComponent from './AsyncComponent'

import NavBar from './components/NavBar';
import Footer from './components/Footer';
import SecondFooter from './components/SecondFooter';

const Privacy = asyncComponent(() =>
    import('./routes/Privacy').then(module => module.default)
)
const Terms = asyncComponent(() =>
    import('./routes/Terms').then(module => module.default)
)
const OpenSource = asyncComponent(() =>
    import('./routes/OpenSource').then(module => module.default)
)
const Captions = asyncComponent(() =>
    import('./routes/Captions').then(module => module.default)
)
const Hashtags = asyncComponent(() =>
    import('./routes/Hashtags').then(module => module.default)
)
const Toolkit = asyncComponent(() =>
    import('./routes/Toolkit').then(module => module.default)
)
const Download = asyncComponent(() =>
    import('./routes/Download').then(module => module.default)
)
const CaptionsCategories = asyncComponent(() =>
    import('./routes/CaptionsCategories').then(module => module.default)
)
const HashtagsCategories = asyncComponent(() =>
    import('./routes/HashtagsCategories').then(module => module.default)
)
const ComingSoon = asyncComponent(() =>
    import('./routes/ComingSoon').then(module => module.default)
)
const TC = asyncComponent(() =>
    import('./tc').then(module => module.default)
)
// import Captions from './routes/Captions';
// import Hashtags from './routes/Hashtags';
// import Toolkit from './routes/Toolkit';
// import Download from './routes/Download';
// import CaptionsCategories from './routes/CaptionsCategories';

class Main extends Component {
    render() {
        return (
            <Router>
                <div>
                    <NavBar />
                    {/* <ul role="nav">
                        <li><Link to="/">Toolkit</Link></li>
                        <li><Link to="/captions">Captions</Link></li>
                        <li><Link to="/hashtags">Hashtags</Link></li>
                        <li><Link to="/download">Download</Link></li> 
                    </ul> */}
                    <div className="content" style={{  paddingBottom: "38px" }}>
                        <div style={{minHeight: '74vh', paddingBottom: "62px", paddingTop: "64px"}}>
                        <Switch >

                            <Route exact path="/" component={Toolkit} />
                            <Route exact path="/toolkit" component={Toolkit} />
                            <Route exact path="/captions" component={CaptionsCategories} />
                            <Route exact path="/search" component={CaptionsCategories} />
                            <Route exact path="/search/:searchQuery" component={Captions} />
                            <Route exact path="/captions-categories" component={CaptionsCategories} />
                            <Route exact path="/captions-categories/:catId" component={Captions} />
                            <Route exact path="/hashtags-categories" component={HashtagsCategories} />
                            <Route exact path="/hashtags-categories/:catId" component={Hashtags} />
                            <Route exact path="/hashtags" component={HashtagsCategories} />
                            <Route path="/app" component={Download} />
                            <Route path="/no-crop-post" component={ComingSoon} />
                            <Route path="/grid-maker" component={ComingSoon} />
                            <Route path="/swipeable-pic" component={ComingSoon} />
                            <Route path="/creative-fonts" component={ComingSoon} />
                            <Route path="/space-adder" component={ComingSoon} />
                            <Route path="/tc" component={TC} />
                            <Route path="/privacy" component={Privacy} />
                            <Route path="/privacy.html" component={Privacy} />
                            <Route path="/terms" component={Terms} />
                            <Route path="/terms.html" component={Terms} />
                            <Route path="/opensource" component={OpenSource} />
                            <Route path="/opensource.html" component={OpenSource} />
                        </Switch>
                        </div>
                        <SecondFooter />
                    </div>
                    <Footer />
                </div>
            </Router>
        );
    }
}

export default Main;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './Main';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const font =  "'Manrope', sans-serif";

// import App from './App';

// use default theme
// const theme = createMuiTheme();

// Or Create your Own theme:
const theme = createMuiTheme({
  typography: {
    fontFamily: font,
  },
  palette: {
    primary: {
      main: '#6c63FF'
    },
    secondary: {
      main: '#FFFFFF'
    }
  }
});

function MainWithTheme() {
  return (
      <MuiThemeProvider theme={theme}>
        <Main />
      </MuiThemeProvider>
  );
}

ReactDOM.render(
  <MainWithTheme />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

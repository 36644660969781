import React from 'react';
import { Divider, Link } from '@material-ui/core';

const links = [{ text: 'Handcrafted with ❤️ by Ankit', path: 'https://ankitsuda.com' }];

const Footer = () => {
    return (
        <div style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: "white"
        }}>
            <Divider style={{ color: '#DCDCDC', height: '1px' }} />
            <div style={{ padding: '8px', textAlign: "right" }}>
                {links.map((value) => (
                    <Link key={links.indexOf(value)} href={value.path} variant="body2" color="inherit" >
                        {value.text}
                    </Link>
                ))}
            </div>

        </div>
    )
}

export default Footer;
import React, { useState } from 'react';
import { InputBase, Divider, Drawer, List, Tab, Tabs, Toolbar, AppBar, ListItem, ListItemText, IconButton } from '@material-ui/core';
import { Link, useHistory } from "react-router-dom";
import { GetAppOutlined, Menu as MenuIcon, Search as SearchIcon } from '@material-ui/icons';
import small_logo from "../imgs/small_logo.png";

const NavBar = () => {
    var styles = {
        appBar: {
            flexWrap: 'wrap',
        },
        tabs: {
            position: 'absolute',
            right: '0',
            fontFamily: 'Manrope M',
        },
        tabs_alt: {
            position: 'absolute',
            right: '0',
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Manrope M',
        },
        search_bar: {
            borderWidth: 0,
            backgroundColor: '#f7f7f7',
            minWidth: '100px',
            textAlign: 'center',
            borderRadius: '25px'
        },
    };


    const { selectedTab, setSelectedTab } = useState(0);
    const history = useHistory();

    let searchInput = React.createRef('');
    let searchInputMobile = React.createRef('');

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    function search() {
        history.push('/search/' + searchInput.current.value);
    }

    function searchMobile() {
        history.push('/search/' + searchInputMobile.current.value);
    }

    const handleKeyDownDesk = (event) => {
       
    
        if (event.key === 'Enter') {
            search();
        }
    }
    const handleKeyDownMobile = (event) => {
    
        if (event.key === 'Enter') {
            searchMobile();
        }
    }

    const list = [{ title: 'Toolkit', path: '/' }, { title: 'Captions', path: '/captions-categories' }, { title: 'Hashtags', path: '/hashtags-categories' }, { title: 'Download', path: '/app' }];


    return (


        <div>
            <AppBar position="fixed" elevation={0} color="secondary" style={styles.appBar} >
                <Toolbar>
                    {/* <Typography variant="title" color="inherit" className="mobile-hide">
                        UNTITLD
                    </Typography>
                 */}
                    <a href="/"><img src={small_logo} alt="logo" className="icon-header" /></a>
                    <div style={styles.tabs_alt}>
                        <div
                           className='search-bar mobile-hide'>

                            <InputBase indicatorColor='transparent' onKeyDown={handleKeyDownDesk} placeholder="Search library (beta)" style={{ paddingLeft: '16px', marginTop: '4%' }} inputRef={searchInput} ></InputBase>
                            <IconButton aria-label="search" onClick={() => search()} className="menu-icon search-icon " style={{ marginLeft: "auto", marginRight: 0 }}>
                                <SearchIcon />
                            </IconButton>
                        </div>
                        <IconButton aria-label="search" onClick={toggleDrawer('top', true)} className="menu-icon search-icon mobile-only " style={{ marginLeft: "auto", marginRight: 0 }}>
                                <SearchIcon />
                            </IconButton>
                        <IconButton aria-label="menu" onClick={toggleDrawer('top', true)} className="menu-icon mobile-only" style={{ marginLeft: "auto", marginRight: 0 }}>
                            <MenuIcon />
                        </IconButton>

                        <div>
                        {/* <div
                            className="mobile-hide" style={styles.search_bar}>

                            <InputBase indicatorColor='transparent' onKeyDown={handleKeyDown} placeholder="Search library (beta)" style={{ paddingLeft: '16px' }} inputRef={searchInput} ></InputBase>
                            <IconButton aria-label="search" onClick={() => search()} className="menu-icon " style={{ marginLeft: "auto", marginRight: 0 }}>
                                <SearchIcon />
                            </IconButton>
                        </div> */}
                        <Tabs
                            value={selectedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            role="nav"
                            variant="scrollable"
                            className="mobile-hide">

                            <Tab label="Toolkit" component={Link} to="/" />
                            <Tab label="Captions" component={Link} to="/captions-categories" />
                            <Tab label="Hashtags" component={Link} to="/hashtags-categories" />
                            <Tab icon={<GetAppOutlined />} component={Link} to="/app" style={{ maxWidth: "64px" }} />
                        </Tabs>
                    </div>

                    </div>
                    

                </Toolbar>

                <Divider style={{ color: '#DCDCDC', height: '2px' }} />
            </AppBar>
            {/* <Drawer anchor="top" onClose={toggleDrawer(false)}
                open={drawerState}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}>
                <List>
                    {list.map((item, index) => (
                        <ListItem button key={item.title} component={Link} to={item.path}>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    ))}
                </List>
            </Drawer> */}
            {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                        <div role="presentation"
                            onClick={toggleDrawer(anchor, false)}
                            onKeyDown={toggleDrawer(anchor, false)}>
                            <List>
                                {list.map((item, index) => (
                                    <ListItem button key={item.title} component={Link} to={item.path}>
                                        <ListItemText primary={item.title} />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                        <div stlye={styles.tabs_alt}>
                        <div
                           className='search-bar'
                           style={{margin: '16px'}}>

                            <InputBase indicatorColor='transparent' onKeyDown={handleKeyDownMobile} placeholder="Search library (beta)" style={{ paddingLeft: '16px', marginTop: '4%' }} inputRef={searchInputMobile} ></InputBase>
                            <IconButton aria-label="search" onClick={() => searchMobile()} className="menu-icon search-icon " style={{ marginLeft: "auto", marginRight: 0 }}>
                                <SearchIcon />
                            </IconButton>
                        </div>
                        </div>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>

    )
}
export default NavBar;
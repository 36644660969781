import React from 'react';
import { Link } from '@material-ui/core';
import { Link as ReactLink } from "react-router-dom";

const links = [{ text: '© 2020 Ankit Suda', path: 'https://ankitsuda.com' }, { text: 'Privacy', path: '/privacy' }, { text: 'Terms', path: '/terms' }, { text: 'Open Source', path: '/opensource' }];

const SecondFooter = () => {
    return (
        <div style={{
            height: 'calc(100% - 60px)',
            backgroundColor: "white"
        }}>


            <div style={{ padding: '8px', textAlign: "center" }}>

                {links.map((value) => (
                    <> {links.indexOf(value) !== 0 ? '  ·  ' : ''} 
                    {
                        value.path.includes('https') ?
                        <Link key={links.indexOf(value)} variant="body2" style={{ color: "#757575" }}  href={value.path} >
                        {value.text}
                    </Link>
                    :    <Link key={links.indexOf(value)} variant="body2" style={{ color: "#757575" }} component={ReactLink} to={value.path} >
                    {value.text}
                </Link>
}
                    </>

                ))}
            </div>

        </div>
    )
}

export default SecondFooter;